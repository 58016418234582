<template>
  <b-form
    @submit.prevent="!form.id ? saveCv() : Update(form.id)"
    class="w-100"
    ref="formReset"
  >
    <h2 class="pb-2" v-if="title">{{ title }}</h2>
    <p class="pb-2" v-if="description">{{ description }}</p>
    <b-row class="w-100 mb-1">
      <b-col cols="12" lg="7">
        <label>Upload Resume ( Supported formats: PDF , Max: 2MB )</label>
        <div class="upload">
          <b-form-file
            id="cv"
            :state="form.cv ? Boolean(form.cv) : null"
            placeholder="Upload Your CV..."
            @change="loadPdf($event)"
            accept=".pdf"
          />
        </div>
        <has-error :form="form" field="cv"></has-error>
      </b-col>

      <b-col cols="12" lg="5">
        <label>CV Name </label>
        <div class="upload">
          <Input
            v-model="form.title"
            placeholder="Write CV Name"
            style="width: 100%"
            type="text"
          />
        </div>
        <has-error :form="form" field="title"></has-error>
      </b-col>
    </b-row>

    <div>
      <b-button variant="primary" type="submit" :disabled="form.busy">
        Save
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        v-if="cvData.length > 0 && nextName && !profile"
        @click="nextOnboard(nextName, nextTitle)"
      >
        Next
      </b-button>
      <router-link to="/profile" v-if="profile">
        <b-button variant="primary" class="ml-1"> Back to profile </b-button>
      </router-link>
    </div>

    <b-row>
      <b-col cols="12 mt-3" v-if="cvData.length">
        <div
          class="upload mb-2 pl-2"
          :class="cv.status ? 'highlight' : ''"
          v-for="(cv, index) in cvData"
          :key="index"
        >
          <b-row class="w-100 align-items-center">
            <b-col
              cols="12"
              lg="1"
              class="bg-danger ml-1 p-1 text-white rounded text-center"
              v-if="cv.cv"
              >PDF</b-col
            >
            <b-col cols="8" lg="8">
              <a :href="cv.cv" target="_blank"
                ><h3 v-if="cv.title">{{ cv.title }}</h3></a
              >
            </b-col>
            <b-col class="text-right">
              <a :href="cv.cv" :download="cv.cv">
                <feather-icon icon="ArrowDownIcon" size="22" />
              </a>
            </b-col>
            <b-col lg="2" class="ml-auto">
              <div class="d-flex align-content-center justify-content-between">
                <div
                  class="checkbox"
                  :class="cv.status ? 'status' : ''"
                  @click="!cv.status ? changeStatus(cv.id) : ''"
                ></div>
                <div>
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="ml-1"
                    role="button"
                    @click="edit(cv.id)"
                  />
                </div>
                <div class="delete-border" v-if="!cv.status">
                  <feather-icon
                    icon="TrashIcon"
                    size="22"
                    class="ml-1 text-danger"
                    role="button"
                    @click="Delete(cv.id)"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
  BFormRadio,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  props: [
    "title",
    "nextOnboard",
    "nextTitle",
    "nextName",
    "description",
    "profile",
  ],
  data() {
    return {
      form: new Form({
        id: null,
        cv: null,
        title: null,
      }),
      cvData: [],
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
    BFormFile,
    BFormRadio,
  },
  mounted() {
    this.getCv();
  },
  methods: {
    loadPdf(e) {
      this.form.cv = e.target.files[0];
    },
    clear() {
      this.form.id = null;
      this.form.cv = null;
      this.form.title = null;
    },
    getCv() {
      axios
        .get("/candidate/cv")
        .then((res) => {
          this.cvData = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    saveCv() {
      this.form
        .post("/candidate/cv")
        .then((res) => {
          this.s(res.data.message);
          this.getCv();
          localStorage.setItem("onboard", "1");
          this.$store.dispatch("onboardData");
          this.$store.dispatch("setToken",{
            token: localStorage.getItem("token"),
            user_name: localStorage.getItem("user_name"),
            email: localStorage.getItem("email"),
            phone: localStorage.getItem("phone"),
            photo: localStorage.getItem("photo"),
            onboard: localStorage.getItem("onboard"),
          });
          this.$router.push({
            name: "dashboard",
          });
          this.clear();
          this.$refs.formReset.reset();
        })
        .catch((e) => {
          if (e.response.data.error) {
            this.e(e.response.data.error);
          } else {
            this.e(e.response.data.message);
          }
        });
    },

    edit(id) {
      axios
        .get("/candidate/cv/" + id)
        .then((res) => {
          let cv = res.data.data;
          this.form.id = cv.id;
          this.form.title = cv.title;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Update(id) {
      this.form
        .post("/candidate/cv/" + id)
        .then((res) => {
          this.s(res.data.message);
          this.clear();
          this.$refs.formReset.reset();
          this.getCv();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/candidate/cv/" + id)
            .then((res) => {
              this.s(res.data.message);
              this.getCv();
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },

    changeStatus(id) {
      axios
        .post("/candidate/cv/status/" + id)
        .then((res) => {
          this.s(res.data.message);
          this.getCv();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.upload {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  transition: background-color 0.3s;
  height: 70px;
}
.upload.highlight {
  border: 2px dashed #4ec76e;
}

.upload:hover {
  background-color: #f7f7f7;
}
.upload .checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
}

.upload .checkbox.status {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #4ec76e;
  background: #4ec76e;
  cursor: help;
}
.delete-border {
  border-left: 2px solid gray;
}
</style>
