<template>
  <div>
    <form @submit.prevent="save(true)" @keydown="form.onKeydown($event)">
      <div class="d-flex justify-content-between flex-lg-row flex-column">
        <div>
          <h2 class="pb-2" v-if="title">{{ title }}</h2>
          <p class="pb-2" v-if="description">{{ description }}</p>
        </div>
        <div class="mb-1 ml-auto" v-if="!hasExperience">
          <b-button variant="info" @click="showConfirmation(false)">No Experience</b-button>
        </div>
      </div>


      <b-row>
        <b-col lg="6">
          <b-row>
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Company Name*</label>
                <Input
                    v-model="form.company_name"
                    placeholder="Enter Company Name"
                    style="width: 100%"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="company_name"
                ></has-error>
              </div>
            </b-col>

            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Designation*</label>
                <Input
                    v-model="form.designation"
                    placeholder="Enter your Designation"
                    style="width: 100%"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="designation"
                ></has-error>
              </div>
            </b-col>

            <b-col lg="12">
              <b-row>
                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label>Starting Date*</label>
                    <DatePicker
                        v-model="starting_date"
                        style="width: 100%"
                        type="date"
                        placeholder="Select Starting date">
                    </DatePicker>
                    <has-error
                        :form="form"
                        field="starting_date"
                    ></has-error>
                  </div>
                </b-col>

                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label>Ending Date</label>
                    <DatePicker
                        v-model="ending_date"
                        type="date"
                        placeholder="Select Ending date"
                        style="width: 100%"
                        :disabled="form.present">
                    </DatePicker>
                    <has-error
                        :form="form"
                        field="ending_date"
                    ></has-error>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <div class="form-group text-left mb-1">
                <p>
                  <Checkbox
                      label="" border
                      v-model="form.present">
                    &nbsp;Currently Working
                  </Checkbox>
                </p>
                <has-error
                    :form="form"
                    field="present"
                ></has-error>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" cols="12">
          <label>Job Description</label>
          <Input
              v-model="form.job_description"
              placeholder="Enter Job Description"
              style="width: 100%"
              type="textarea"
              :rows="4"
          />
          <has-error
              :form="form"
              field="job_description"
          ></has-error>
        </b-col>
      </b-row>

      <div class="w-100 text-right">
        <b-row class="pt-1">
          <b-col class="d-flex  justify-content-between"
                 cols="4"
                 lg="6"
          >
            <b-button
                variant="primary"
                v-if="hasExperience && !profile"
                @click="nextOnboard(nextName, nextTitle)"
            >
              Next
            </b-button>
            <router-link to="/profile" v-if="profile">
              <b-button
                  variant="primary"
                  class="ml-1">
                Back to profile
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="8" lg="6">
            <b-button
                variant="primary"
                type="submit"
                :disabled="form.busy"
            >
              {{ form.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
                variant="danger"
                class="ml-1"
                @click="clear()"
            >
              Clear
            </b-button>
          </b-col>
        </b-row>
      </div>
    </form>
    <hr class="mt-2">

    <div class="card border"
         v-for="(experience, index) in experiences"
         v-if="experiences.length"
         :key="index"
    >
      <div class="card-body experience-card">
        <b-row>
          <b-col cols="12" lg="12"
                 class="d-flex
                 justify-lg-content-between flex-column flex-lg-row"
          >
            <div class="order-1 order-lg-0">
              <h5 class="card-title font-weight-bolder">{{ experience.company_name }}</h5>
            </div>
            <div class="order-0 order-lg-1 ml-auto">
              <button
                  class="btn btn-primary btn-sm"
                  @click="edit(experience.id)"
              >
                <feather-icon icon="EditIcon"/>
              </button>
              <button
                  class="btn btn-danger btn-sm ml-1"
                  @click="destroy(experience.id)"
              >
                <feather-icon icon="TrashIcon"/>
              </button>
            </div>
          </b-col>

          <b-col cols="12">
            <h6 class="card-subtitle mb-2 text-secondary">
              ({{ experience.starting_date }} - {{ experience.ending_date }})</h6>
          </b-col>
          <b-col cols="12">
            <h6 class="card-subtitle mb-2 text-muted" v-if="experience.duration">
              {{ experience.duration }} of
              Experience</h6>
          </b-col>
          <b-col cols="12">
            <h6 class="card-subtitle mb-2 designation">{{ experience.designation }}</h6>
          </b-col>
          <b-col>
            <p class="card-text">{{ experience.job_description }}</p>
          </b-col>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";

export default {
  props: ["title", "nextOnboard", "nextTitle", "nextName", "description", "profile"],
  data() {
    return {
      form: new Form({
        id: null,
        company_name: null,
        designation: null,
        starting_date: null,
        ending_date: null,
        job_description: null,
        present: null,
        duration: null,
        status: true
      }),
      starting_date: null,
      ending_date: null,
      hasExperience: true,
      showModal: false,
      experiences: {},
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
  },
  mounted() {
    this.showExperience()
  },
  methods: {
    clear() {
      this.form.id = null;
      this.form.company_name = null;
      this.form.designation = null;
      this.form.starting_date = null;
      this.starting_date = null;
      this.ending_date = null;
      this.form.ending_date = null;
      this.form.job_description = null;
      this.form.status = true;
      this.form.present = false;
    },

    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    save(status) {
      this.form.status = status;
      this.form.starting_date = this.formatDate(this.starting_date);
      this.form.ending_date = this.form.present ? null : this.formatDate(this.ending_date);
      if (!this.form.id) {
        this.form.post('/candidate/experiences')
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.showExperience();
              this.$store.dispatch("onboardData");
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      } else {
        this.form.put('/candidate/experiences/' + this.form.id)
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.showExperience();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      }
    },
    edit(id) {
      axios
          .get("/candidate/experiences/" + id + "/edit")
          .then((res) => {
            let experience = res.data.experience;
            this.form.id = experience.id;
            this.form.company_name = experience.company_name;
            this.form.designation = experience.designation;
            this.starting_date = experience.starting_date;
            this.ending_date = experience.ending_date;
            this.form.job_description = experience.job_description;
            this.form.present = !experience.ending_date ? true : false;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    destroy(id) {
      axios
          .delete("/candidate/experiences/" + id)
          .then((response) => {
            this.s(response.data.message);
            this.showExperience();
            this.$store.dispatch("onboardData");
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showExperience() {
      axios
          .get("/candidate/experiences")
          .then((res) => {
            this.hasExperience = res.data.data.some((item) => item.id) ? true : false;
            this.experiences = res.data.data.filter((item) => item.status === 1);
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showConfirmation(status) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You have no Experience!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.save(status);
        }
      })
    },
  }
};
</script>

<style scoped>
.onboard-form .card-body {
  display: block !important;
}

.experience-card {
  background-color: #fafafa;
}

.designation {
  color: #0c389f;
}
</style>